import HomeBar from "../HomePage/HomeBar";
import MetaTags from 'react-meta-tags';
import styles from './mentionsLegales.module.css'


function MentionsLegales() {

    return(
        <div>
            <MetaTags>
                <title>Guide aux énergies</title>
                <meta property="og:description" content="Guide aux énergies"/>
                <meta property="og:title" content="Guide aux énergies"></meta>
            </MetaTags>
            <HomeBar/>
            <div className={styles.content}>
                <div className={styles.base}>
                    <div className={styles.title}>
                        Politique de Confidentalité de guide aux énergies
                    </div>
                    <div className={styles.container}>
                    <div className={styles.names}>
                        <p>La présente Politique de confidentialité décrit la façon dont vos informations personnelles sont recueillies, utilisées et partagées lorsque vous vous rendez sur https://guide-aux-energies.fr/ (le « Site ») ou que vous y effectuez un achat.</p>
                    </div>
                    </div>
                    <div className={styles.container}>
                        <div className={styles.subtitle}>
                            INFORMATIONS PERSONNELLES RECUEILLIES
                        </div>
                        <div className={styles.names}>
                            <p>Lorsque vous vous rendez sur le Site, nous recueillons automatiquement certaines informations concernant votre appareil, notamment des informations sur votre navigateur web, votre adresse IP, votre fuseau horaire et certains des cookies qui sont installés sur votre appareil.</p>
                            <p>En outre, lorsque vous parcourez le Site, nous recueillons des informations sur les pages web ou produits individuels que vous consultez, les sites web ou les termes de recherche qui vous ont permis d'arriver sur le Site, ainsi que des informations sur la manière dont vous interagissez avec le Site. Nous désignons ces informations collectées automatiquement sous l'appellation « Informations sur l'appareil ».</p>
                            <p>Nous recueillons les Informations sur l'appareil à l'aide des technologies suivantes :</p>
                        </div>
                        <div className={styles.subtile}>
                            FICHIERS TÉMOINS (COOKIES)
                        </div>
                        <div className={styles.names}>
                            <p>Voici une liste de fichiers témoins que nous utilisons. Nous les avons énumérés ici pour que vous ayez la possibilité de choisir si vous souhaitez les autoriser ou non.</p>
                            <p>
                             _session_id, identificateur unique de session, permet  de stocker les informations relatives à votre session (référent, page de renvoi, etc.) ; aucune donnée retenue, persiste pendant 30 minutes depuis la dernière visite. Utilisé par le système interne de suivi des statistiques du fournisseur de notre site web pour enregistrer le nombre de visites.<br/>
                             aucune donnée retenue, expire à minuit (selon l’emplacement du visiteur) le jour suivant. Calcule le nombre de visites d’une boutique par client unique.<br/>
                             cart, identificateur unique, persiste pendant 2 semaines, stocke l’information relative à votre panier d’achat.<br/>
                             _secure_session_id, identificateur unique de session.<br/>
                             storefront_digest, identificateur unique, indéfini si la boutique possède un mot de passe, il est utilisé pour savoir si le visiteur actuel a accès.<br/>
                             - Les « fichiers journaux » suivent l'activité du Site et recueillent des données telles que votre adresse IP, le type de navigateur que vous utilisez, votre fournisseur d'accès Internet, vos pages référentes et de sortie, et vos données d'horodatage (date et heure).<br/>
                             - Les « pixels invisibles », les « balises » et les « pixels » sont des fichiers électroniques qui enregistrent des informations sur la façon dont vous parcourez le Site.
                             [[INSÉRER LES DESCRIPTIONS DES AUTRES TYPES DE TECHNOLOGIES DE SUIVI QUE VOUS UTILISEZ]]<br/>
                            </p>
                            <p>Par ailleurs, lorsque vous effectuez ou tentez d'effectuer un achat par le biais du Site, nous recueillons certaines informations vous concernant, notamment votre nom, votre adresse de facturation, votre adresse d'expédition, vos informations de paiement (y compris vos numéros de cartes de crédit [[INSÉRER LES AUTRES MOYENS DE PAIEMENT ACCEPTÉS, LE CAS ÉCHÉANT]]), votre adresse e-mail et votre numéro de téléphone.</p>
                            <p>Ces informations collectées automatiquement sont désignées par l’appellation « Informations sur la commande ».</p>
                            <p>[[INSÉRER TOUTES LES AUTRES INFORMATIONS QUE VOUS RECUEILLEZ, LE CAS ÉCHÉANT : DONNÉES HORS LIGNE, DONNÉES/LISTES ACHETÉES À DES FINS DE MARKETING]]</p>
                            <p>Lorsque nous utilisons l'expression « Informations personnelles » dans la présente Politique de confidentialité, nous faisons allusion à la fois aux Informations sur l'appareil et aux Informations sur la commande.</p>
                        </div>
                        <div className={styles.subtitle}>
                            COMMENT UTILISONS-NOUS VOS INFORMATIONS PERSONNELLES ?
                        </div>
                        <div className={styles.names}>
                            <p>En règle générale, nous utilisons les Informations sur la commande que nous recueillons pour traiter toute commande passée par le biais du Site (y compris pour traiter vos informations de paiement, organiser l'expédition de votre commande et vous fournir des factures et/ou des confirmations de commande).  En outre, nous utilisons ces Informations sur la commande pour : communiquer avec vous ; évaluer les fraudes ou risques potentiels ; et lorsque cela correspond aux préférences que vous nous avez communiquées, vous fournir des informations ou des publicités concernant nos produits ou services. [[INSÉRER LES AUTRES UTILISATIONS QUI SONT FAITES DES INFORMATIONS SUR LA COMMANDE]] Nous utilisons les Informations sur l'appareil (en particulier votre adresse IP) que nous recueillons pour évaluer les fraudes ou risques potentiels et, de manière plus générale, pour améliorer et optimiser notre Site (par exemple, en générant des analyses sur la façon dont nos clients parcourent et interagissent avec le Site, et pour évaluer la réussite de nos campagnes de publicité et de marketing). [[INSÉRER LES AUTRES UTILISATIONS QUI SONT FAITES DES INFORMATIONS SUR L'APPAREIL, NOTAMMENT EN MATIÈRE DE PUBLICITÉ ET DE RECIBLAGE]] PARTAGE DE VOS INFORMATIONS PERSONNELLES</p>
                            <p>Nous partageons vos Informations personnelles avec des tiers qui nous aident à les utiliser aux fins décrites précédemment.  Par exemple, nous utilisons notre site pour héberger notre boutique en ligne – pour en savoir plus sur l'utilisation de vos Informations personnelles, veuillez consulter la page suivante : Nous utilisons également Google Analytics pour mieux comprendre comment nos clients utilisent le Site – pour en savoir plus sur l'utilisation de vos Informations personnelles par Google, veuillez consulter la page suivante : https://www.google.com/intl/fr/policies/privacy/.  Vous pouvez aussi désactiver Google Analytics ici : https://tools.google.com/dlpage/gaoptout.</p>
                            <p>Enfin, il se peut que nous partagions aussi vos Informations personnelles pour respecter les lois et règlementations applicables, répondre à une assignation, à un mandat de perquisition ou à toute autre demande légale de renseignements que nous recevons, ou pour protéger nos droits.</p>
                            <p>[[INCLURE LE PARAGRAPHE SUIVANT SI VOUS UTILISEZ LE MARKETING DE RELANCE OU LA PUBLICITÉ CIBLÉE]]</p>
                        </div>
                        <div className={styles.subtitle}>
                            PUBLICITÉ COMPORTEMENTALE
                        </div>
                        <div className={styles.names}>
                            <p>Comme indiqué ci-dessus, nous utilisons vos Informations personnelles pour vous proposer des publicités ciblées ou des messages de marketing qui, selon nous, pourraient vous intéresser.  Pour en savoir plus sur le fonctionnement de la publicité ciblée, vous pouvez consulter la page d'information de la Network Advertising Initiative (NAI) à l'adresse suivante : http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work.
                            </p>
                            <p>Vous pouvez refuser la publicité ciblée ici :[[INCLURE LES LIENS DE DÉSACTIVATION DES SERVICES UTILISÉS.
                            QUELQUES LIENS COURANTS :
                            FACEBOOK – https://www.facebook.com/settings/?tab=ads
                            GOOGLE – https://www.google.com/settings/ads/anonymous
                            BING – https://about.ads.microsoft.com/fr-fr/ressources/politiques/annonces-personnalisees
                            ]]</p>
                            <p>En outre, vous pouvez refuser certains de ces services en vous rendant sur le portail de désactivation de Digital Advertising Alliance à l'adresse suivante : https://optout.aboutads.info/?c=3&lang=fr.</p>
                        </div>
                        <div className={styles.subtitle}>
                            NE PAS SUIVRE
                        </div>
                        <div className={styles.names}>
                            <p>Veuillez noter que nous ne modifions pas la collecte de données de notre Site et nos pratiques d'utilisation lorsque nous détectons un signal « Ne pas suivre » sur votre navigateur.</p>
                        </div>
                        <div className={styles.subtitle}>
                            VOS DROITS
                        </div>
                        <div className={styles.names}>
                            <p>Si vous êtes résident(e) européen(ne), vous disposez d'un droit d'accès aux informations personnelles que nous détenons à votre sujet et vous pouvez demander à ce qu'elles soient corrigées, mises à jour ou supprimées. Si vous souhaitez exercer ce droit, veuillez nous contacter au moyen des coordonnées précisées ci-dessous. Par ailleurs, si vous êtes résident(e) européen(ne), notez que nous traitons vos informations dans le but de remplir nos obligations contractuelles à votre égard (par exemple si vous passez une commande sur le Site) ou de poursuivre nos intérêts commerciaux légitimes, énumérés ci-dessus.  Veuillez également noter que vos informations seront transférées hors de l'Europe, y compris au Canada et aux États-Unis.</p>
                        </div>
                        <div className={styles.subtitle}>
                            RÉTENTION DES DONNÉES
                        </div>
                        <div className={styles.names}>
                            <p>Lorsque vous passez une commande par l'intermédiaire du Site, nous conservons les Informations sur votre commande dans nos dossiers, sauf si et jusqu'à ce que vous nous demandiez de les supprimer.</p>
                        </div>
                        <div className={styles.subtitle}>
                            CHANGEMENTS
                        </div>
                        <div className={styles.names}>
                            <p>Nous pouvons être amenés à modifier la présente politique de confidentialité de temps à autre afin d'y refléter, par exemple, les changements apportés à nos pratiques ou pour d'autres motifs opérationnels, juridiques ou réglementaires.</p>
                        </div>
                        <div className={styles.subtitle}>
                            NOUS CONTACTER
                        </div>
                        <div className={styles.names}></div>
                        <p>Pour en savoir plus sur nos pratiques de confidentialité, si vous avez des questions ou si vous souhaitez déposer une réclamation, veuillez nous contacter par e-mail à guideauxenergies@gmail.com.</p>
                    </div>
                </div>
            </div>
        </div>
    )
    
}

export default MentionsLegales
