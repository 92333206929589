import ReactDOM from 'react-dom'
import Request from './RequestApi';

import styles from './PopUp.module.css'
import { useCookies } from 'react-cookie';
import { Link } from 'react-router-dom';
import { idText } from 'typescript';
import React, { useEffect, useState } from "react";



export enum popUpType {
    HIDDEN = 0,
    CREATION_QUESTION,
    SUCCESS_CREATION_QUESTION,
    NOT_CONNECTED,
    ERROR
}

interface popUpParams {
    type: popUpType;
    setType: any;
    categories: any;
}

function strToUrlName(str: string) {
    return str
        .split(' ')
        .map((word) => word.toLowerCase())
        .join('_')
        .replace(/\W/g, '');
}

function useLocalStorageState(str: string, int: number) {
    const [state, setState] = useState(() => {
      let value
      try {
        value = JSON.parse(
          window.localStorage.getItem('my-app-count') || JSON.stringify(int)
        )
      } catch (e) {
        value = int;
      }
      return value;
    })
  
    useEffect(() => {
      window.localStorage.setItem(str, state);
    }, [state])
  
    return [state, setState];
}

const PopUp = ({type, setType, categories} : popUpParams) => {
    const [count, setCount] = useLocalStorageState('my-app-count', 0);
    const [cookies] = useCookies(["id"]);
    const [question, setQuestion] = React.useState()
    const [status, setStatus] = React.useState("")
    async function postQuestion(e: any) {
        e.preventDefault()
        const title = e.target.children.title.value
        const description = e.target.children.description.value
        const categoryId = e.target.children.categoryId.value
        const photo = e.target.children.photo.files[0]
        let form_data = new FormData();
        form_data.append('title', title);
        form_data.append('urlTitle', strToUrlName(title));
        form_data.append('description', description);
        form_data.append('category', categoryId);
    
        if (type === popUpType.CREATION_QUESTION) {
            const userId = cookies.id
            form_data.append('userId', userId);
        }
        else {
            const userId = e.target.children.userIdNotRegister.value
            form_data.append('username', userId);
        }
        form_data.append('photo', photo);
        if (description === "" || title === "")
            return
        Request.postQuestion(form_data).then((res => {
            if (res.error) {
                setStatus(res.error)
                setType(popUpType.ERROR)
                if (res.error = "L'id utilisateur est invalide")
                    setType(popUpType.SUCCESS_CREATION_QUESTION)
            } else {
                setType(popUpType.SUCCESS_CREATION_QUESTION)
            }
        }))
    }

    function createQuestionPopUp() {
        return (
            <>
                <div onClick={() => {setType(popUpType.HIDDEN)}} className={styles.modalOverlay}></div>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalInner}>
                        <div className={styles.title}>Je pose ma question</div>
                        <form className={styles.form} onSubmit={(e) => postQuestion(e)}>
                            <input autoComplete="off" name="title" className={styles.question} placeholder="Renseigner ma question"></input>
                            <textarea name="description" className={styles.description} placeholder="Je décris mon problème ..."></textarea>
                            <select name="categoryId" className={styles.category}>
                                {categories?.map((elem: any) => {
                                    return <option key={elem.category.id} value={elem.category.id}>{elem.category.Title}</option>
                                })}
                            </select>
                                <label className={styles.custom_file_label}>
                                    Ajouter une photo ?
                                </label>
                                <input accept="image/*" name="photo" className={styles.custom_file_input} type="file"/>
                            <div className={styles.hintDiv}>
                                <img className={styles.hintImg} src="../hint.png" alt="hint"/>
                                <div className={styles.hint}>Un expert vous apportera sa réponse dans les plus brefs délais</div>
                            </div>
                            <button className={styles.button}>Valider</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }

    function createQuestionPopUpNotLogin() {
        return (
            <>
                <div onClick={() => {setType(popUpType.HIDDEN)}} className={styles.modalOverlay}></div>
                <div className={styles.modalWrapper}>
                    <div className={styles.modalInner}>
                        <div className={styles.title}>Je pose ma question</div>
                        <form className={styles.form} onSubmit={(e) => postQuestion(e)}>
                            <input required autoComplete="off" name="userIdNotRegister" className={styles.question} placeholder="Pseudo *"></input>
                            <input autoComplete="off" name="title" className={styles.question} placeholder="Renseigner ma question"></input>
                            <textarea name="description" className={styles.description} placeholder="Je décris mon problème ..."></textarea>
                            <select name="categoryId" className={styles.category}>
                                {categories?.map((elem: any) => {
                                    return <option key={elem.category.id} value={elem.category.id}>{elem.category.Title}</option>
                                })}
                            </select>
                                <label className={styles.custom_file_label}>
                                    Ajouter une photo ?
                                </label>
                                <input accept="image/*" name="photo" className={styles.custom_file_input} type="file"/>
                            <div className={styles.hintDiv}>
                                <img className={styles.hintImg} src="../hint.png" alt="hint"/>
                                <div className={styles.hint}>Un expert vous apportera sa réponse dans les plus brefs délais</div>
                            </div>
                            <button className={styles.button} onClick={() => setCount(count + 1)}>Valider</button>
                        </form>
                    </div>
                </div>
            </>
        )
    }

    function successQuestionPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.successWrapper}>
                        <div className={styles.successContainer}>
                            <img className={styles.imgValidate} src="../validate.png" alt="validate"/>
                            <h1 className={styles.success}>Votre question a bien été postée</h1>
                            <div className={styles.hintDiv}>
                                <img className={styles.hintImg} src="../red_hint.png" alt="hint"/>
                                <div className={`${styles.hint} ${styles.red}`}>Un expert reviendra vers vous dans les plus brefs délais</div>
                            </div>
                        </div>
                    </div>
                </>
            )
    }

    function errorPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.successWrapper}>
                        <div className={styles.successContainer}>
                            <img className={styles.imgValidate} src="../red_alert.png" alt="error"/>
                            <h1 className={styles.success}>Une erreur non expectée s'est produite</h1>
                            <div className={styles.hintDiv}>
                                <img className={styles.hintImg} src="../red_hint.png" alt="hint"/>
                                <div className={`${styles.hint} ${styles.red}`}>Nous sommes désolés veuillez réessayer</div>
                                <div className={styles.errorStatus}>{status}</div>
                            </div>
                        </div>
                    </div>
                </>
            )
    }

    function notConnectedPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.successWrapper}>
                        <div className={styles.successContainer}>
                            <img className={styles.imgValidate} src="../red_alert.png" alt="validate"/>
                            <h1 className={styles.success}>Veuillez vous inscrire pour poser une question</h1>
                            <Link to="/signin">
                                <button className={styles.buttonSignin}>Inscription</button>
                            </Link>
                        </div>
                    </div>
                </>
            )
    }
    return (
        <>
            {type === popUpType.NOT_CONNECTED && count >= 3 ?
                ReactDOM.createPortal(notConnectedPopUp(), document.body)
            : null}
            {type === popUpType.CREATION_QUESTION ?
                ReactDOM.createPortal(createQuestionPopUp(), document.body)
            : null}
            {type === popUpType.SUCCESS_CREATION_QUESTION ?
                ReactDOM.createPortal(successQuestionPopUp(), document.body)
            : null}
            {type === popUpType.NOT_CONNECTED && count < 3 ?
                ReactDOM.createPortal(createQuestionPopUpNotLogin(), document.body)
            : null}
            {type === popUpType.ERROR ?
                ReactDOM.createPortal(errorPopUp(), document.body)
            : null}
        </>
    )
}

export default PopUp
