import React from 'react'
import styles from './questionPage.module.css'
import { Link } from 'react-router-dom'
import SearchBar from '../Usefull/SearchBar'
import Modal, { popUpType } from '../Usefull/PopUp'
import Request from '../Usefull/RequestApi'
import usePopUp from '../Usefull/usePopUp'
import { useCookies } from 'react-cookie'

function QuestionBar() {
    const { type, setType } = usePopUp();
    const [categories, setCategories] = React.useState([]);
    const [cookies] = useCookies(["user"])
    React.useEffect(() => {
        Request.getCategories().then((categories) => { setCategories(categories) })
    }, [type]);
    return (
        <div className={styles.appBar}>
            <Modal type={type} setType={setType} categories={categories}/>
            <Link className={styles.homeLink} to={"/"}>
                <img className={styles.imgLogo} src="../logo.png" alt="logo"></img>
                <h1 className={styles.title}>Guide aux énergies</h1>
            </Link>
            <SearchBar hidden={true}></SearchBar>
            <button className={styles.button} onClick={() => {cookies.user ? setType(popUpType.CREATION_QUESTION) : setType(popUpType.NOT_CONNECTED)}}>Je pose ma question ?</button>
        </div>
    )
}

export default QuestionBar
