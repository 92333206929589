import React from 'react'
import Request from '../Usefull/RequestApi'
import HomeBar from '../HomePage/HomeBar';
import { Link } from 'react-router-dom';
import styles from './Log.module.css';
import Footer from '../layout/Footer';

function Signin() {
    const [status, setStatus] = React.useState("")

    async function registerDB(e: any) {
        e.preventDefault()
        
        const target = e.target as HTMLTextAreaElement;
        const username = (target.children.namedItem("username") as HTMLInputElement).value
        const email = (target.children.namedItem("email") as HTMLInputElement).value
        const password = (target.children.namedItem("password") as HTMLInputElement).value
        const phone = (target.children.namedItem("phone") as HTMLInputElement).value
        if (username === "" || email === "" || password === "" || phone === "")
            return
        Request.signUp(username, email, password, phone).then((res) => {
            setStatus(res)
            e.target.reset()
        }).catch((error) => {
            setStatus(error)
        })
    }
    return (
        <div className={styles.logPage}>
            <HomeBar></HomeBar>
            <div className={styles.logContainer}>
                <div className={styles.title}>Inscription</div>
                <form id="signinForm"className={styles.form} onSubmit={(e) => registerDB(e)}>
                    <input className={styles.input} required name="username" placeholder="Pseudo"></input>
                    <input className={styles.input} required type="email" name="email" placeholder="Email"></input>
                    <input className={styles.input} required type="password" name="password" placeholder="Mot de passe"></input>
                    <input className={styles.input} required type="tel" pattern="[0-9]{10,25}" name="phone" placeholder="Téléphone"></input>
                    <div className={styles.errorStatus}>{status}</div>
                    <button type='submit' className={styles.button}>Inscription</button>
                </form>
                <div className={styles.infoContainer}>
                    <div className={styles.infoAccount}>Vous avez déjà un compte ?
                        <Link className={styles.infoLink} to='/login'>Connectez vous ici</Link>
                    </div>
                </div>
            </div>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <Footer/>
        </div>
)
}

export default Signin
