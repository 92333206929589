import React from 'react'
import { popUpType } from './PopUpForm';

const usePopUp = () => {
    const [type, setType] = React.useState(popUpType.HIDDEN)

    return {
      type,
      setType
    };
  };

export default usePopUp
