import React from 'react'
import Request from '../Usefull/RequestApi'
import styles from './Log.module.css';
import HomeBar from '../HomePage/HomeBar';
import Footer from '../layout/Footer';
import { useCookies } from 'react-cookie';


function PhoneConnection() {
    const [cookies, setCookie] = useCookies(["user"]);
    const [getId, setId] = useCookies(['id'])
    const [status, setStatus] = React.useState("")

    async function loginDB(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const target = e.target as HTMLTextAreaElement;
        const userId = getId.id
        const phone = (target.children.namedItem("phone") as HTMLInputElement).value
        if (phone === "" || userId === "")
        return
        Request.postPhoneNumber(userId, phone).then((res: { [x: string]: any; error: any; }) => {
            if (res.error) {
                setStatus(res.error)
            } else {
                setCookie('user', res["username"])
                setId('id', res["id"])
                window.location.assign(window.location.href.replace('phone-connection', ''))
            }
        })
    }

    return (
        <div className={styles.logPage}>
            <HomeBar></HomeBar>
            <div className={styles.logContainer}>
                <div className={styles.title}>Finir mon inscription</div>
                <form className={styles.form} onSubmit={(e) => loginDB(e)}>
                <input className={styles.input} required type="tel" pattern="[0-9]{10,25}" name="phone" placeholder="Téléphone"></input>
                    <button type='submit' className={styles.button}>Créer mon compte</button>
                </form>
            </div>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <Footer/>
        </div>
    )
}

export default PhoneConnection;