import ReactDOM from 'react-dom'
import React from 'react'
import Request from './RequestApi';
import styles from './PopUpForm.module.css'


export enum popUpType {
    HIDDEN = 0,
    ERROR,
    FORMCONSEIL,
    SUCCESS_SEND_FORM,
}

interface popUpParams {
    type: popUpType;
    setType: any;
    categories: any;
    modes_chauffage: any;
}


const PopUpForm = ({type, setType, categories, modes_chauffage} : popUpParams) => {
    const [status, setStatus] = React.useState("")
    async function sendForm(e: any) {
        e.preventDefault()
        const name = e.target.children.name.value
        const firstname = e.target.children.firstname.value
        const email = e.target.children.email.value
        const postcode = e.target.children.postcode.value
        const categorie_id = e.target.children.categorie_id.value
        const mode_chauffage = e.target.children.mode_chauffage.value
        const problem = e.target.children.problem.value
        let form_data = new FormData();
        form_data.append('name', name);
        form_data.append('firstname', firstname);
        form_data.append('email', email);
        form_data.append('postcode', postcode);
        form_data.append('categorie_id', categorie_id);
        form_data.append('mode_chauffage', mode_chauffage);
        form_data.append('problem', problem);
        if (name === "" || firstname === "" || email === "" || postcode === "" || categorie_id === "" || mode_chauffage === "" || problem === "")
            return
        Request.sendForm(form_data).then((res => {
            if (res.error) {
                setStatus(res.error)
                setType(popUpType.ERROR)
            } else {
                setType(popUpType.SUCCESS_SEND_FORM)
            }
        }))
    }

    function conseilPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.modalWrapper}>
                        <div className={styles.modalInner}>
                            <div className={styles.title}>Demande de conseil</div>
                            <form id="signinForm"className={styles.form} onSubmit={(e) => sendForm(e)}> 
                                <input className={styles.input} required name="name" placeholder="Nom"/>
                                <input className={styles.input} required name="firstname" placeholder="Prénom"/>
                                <input className={styles.input} required type="email" name="email" placeholder="Email"/>
                                <input className={styles.input} required type="number" name="postcode" placeholder="Code postal"/>
                                <select className={styles.select} required name="categorie_id">
                                    <option  disabled={true} selected={true} value="">Catégorie</option>
                                    {categories?.map((elem: any) => {
                                        return <option key={elem.category.id} value={elem.category.id}>{elem.category.Title}</option>
                                    })}
                                </select>
                                <select className={styles.select} required name="mode_chauffage">
                                    <option  disabled={true} selected={true} value="">Mode de chauffage</option>
                                    {modes_chauffage?.map((elem: any) => {
                                        return <option key={elem[0]} value={elem[1]}>{elem[1]}</option>
                                    })}
                                </select>
                                <textarea className={styles.input} required name="problem" placeholder="Ma demande"/>
                                <div className={styles.errorStatus}>{status}</div>
                                <button type='submit' className={styles.button}>Envoyer</button>
                            </form>
                        </div>
                    </div>
                </>
            )
    }

    function successFormPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.successWrapper}>
                        <div className={styles.successContainer}>
                            <img className={styles.imgValidate} src="../validate.png" alt="validate"/>
                            <h1 className={styles.success}>Votre demande a bien été envoyée !</h1>
                        </div>
                    </div>
                </>
            )
    }

    function errorPopUp() {
        return (
                <>
                    <div onClick={() => { setType(popUpType.HIDDEN); } } className={styles.modalOverlay}></div>
                    <div className={styles.successWrapper}>
                        <div className={styles.successContainer}>
                            <img className={styles.imgValidate} src="../red_alert.png" alt="error"/>
                            <h1 className={styles.success}>Une erreur non expectée s'est produite</h1>
                            <div className={styles.hintDiv}>
                                <img className={styles.hintImg} src="../red_hint.png" alt="hint"/>
                                <div className={`${styles.hint} ${styles.red}`}>Nous sommes désolés veuillez réessayer</div>
                                <div className={styles.errorStatus}>{status}</div>
                            </div>
                        </div>
                    </div>
                </>
            )
    }

    return (
        <>
            {type === popUpType.ERROR ?
                ReactDOM.createPortal(errorPopUp(), document.body)
            : null}
            {type === popUpType.FORMCONSEIL ?
                ReactDOM.createPortal(conseilPopUp(), document.body)
            : null}
            {type === popUpType.SUCCESS_SEND_FORM ?
                ReactDOM.createPortal(successFormPopUp(), document.body)
            : null}
        </>
    )
}

export default PopUpForm
