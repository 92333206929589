import React from 'react'
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";

import HomePage from './components/HomePage/HomePage';
import QuestionPage from './components/QuestionPage/QuestionPage';
import Login from './components/LogPage/Login';
import Signin from './components/LogPage/Signin';
import { CookiesProvider } from 'react-cookie';
import MentionsLegales from './components/MentionsLegales/MentionsLegales';
import ResetPassword from './components/LogPage/ResetPassword';
import ForgotChangePassword from './components/LogPage/ForgotChangePassword';
import PhoneConnection from './components/LogPage/PhoneConnection';
import NotFound from './components/Usefull/NotFound'

function App() {
    return (
        <CookiesProvider>
            <Router>
                <Routes>
                    <Route path="*" element={<NotFound />} />
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/signin" element={<Signin/>}/>
                    <Route path="/phone-connection/" element={<PhoneConnection/>}/>
                    <Route path="/questions/:id" element={<QuestionPage/>}/>
                    <Route path="/legal-notice" element={<MentionsLegales/>}/>
                    <Route path="/reset-password" element={<ResetPassword/>}/>
                    <Route path="/change-password/:user_id/:token" element={<ForgotChangePassword/>}/>
                </Routes>
            </Router>
        </CookiesProvider>
    )
}

export default App
