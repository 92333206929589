import React from 'react'

import { Question } from './Categories';
import Request from './RequestApi'

import styles from './searchBar.module.css'

function SearchBar( { hidden } ) {
    const [listQuestion, setListQuestion] = React.useState()

    async function findQuestion(e) {
        e.preventDefault()
        const searchTitle = e.target.value
        if (searchTitle === "") {
            setListQuestion([])
            return
        }
        Request.searchQuestions(searchTitle).then((questions) => { 
            setListQuestion(questions)
        })
    }
    React.useEffect(() => {
        const hide = function(e){   
            e.stopPropagation()
            if (!document.getElementById('searchcontainer')?.contains(e.target)){
                setListQuestion([])
            }
        }
        window.addEventListener('click', hide);
        return () => {
            window.removeEventListener('click', hide)
        }
    }, [])
    return (
            <div>
                <div id="searchcontainer" className={styles.searchBarContainer + " " + styles.miniSearchBar + (hidden ? ' ' + styles.hidden : null)}>
                    <input autoComplete="off" aria-label="Search" onChange={(e) => findQuestion(e)} className={styles.searchBar} name="searchTitle" onMouseOver={(e) => {e.target.placeholder = "Je décris mon problème"}} onMouseOut={(e) => {e.target.placeholder = ""}} onBlur={(e) => {e.target.value = ""}}></input>
                    <img className={styles.imgSearch} src="../search.png" alt="search"/>
                    <div className={styles.floatQuestionQuery}>
                        { listQuestion?.map((question) => {
                            return <Question key={question.id} urlTitle={question.UrlTitle} title={question.Title} id={question.id}></Question>
                        })}
                    </div>
                </div>
            </div>
        )
}

export default SearchBar
