import React from 'react'
import { Link } from 'react-router-dom'
import styles from './homePage.module.css'
import { useCookies } from 'react-cookie';
import usePopUp from "../Usefull/usePopUpForm";
import Modal, { popUpType } from "../Usefull/PopUpForm";
import Request from '../Usefull/RequestApi';


function HomeBar() {
    const [user] = useCookies(["user"]);
    const [cookies, , removeCookies] = useCookies()
    const [username, setUsername] = React.useState("")
    const [categories, setCategories] = React.useState([]);
    const [modes_chauffage, setModes_chauffage] = React.useState([]);
    const { type, setType } = usePopUp();

    React.useEffect(() => {
        Request.getCategories().then((categories) => { setCategories(categories) })
        Request.getModes_chauffage().then((modes_chauffage) => { setModes_chauffage(modes_chauffage) })
    }, [type]);

    function logout() {
        removeCookies("id")
        removeCookies("user")
        removeCookies("csrftoken")
    }

    React.useState(() => {
        try {
            const newUsername = (user?.user)[0].toUpperCase() + (user?.user.substring(1))
            setUsername(newUsername)
        } catch (e) {
            setUsername("")
            logout()
        }
    })

    return (
        <div className={styles.appBar}>
            <Modal type={type} setType={setType} categories={categories} modes_chauffage={modes_chauffage}/>
            <Link className={styles.homeLink} to={"/"}>
                <img className={styles.imgLogo} src="logo.png" alt="logo"></img>
                <h1 className={styles.title}>Guide aux énergies</h1>
            </Link>
            <div className={styles.logButtons}>
                <Link to="" className={styles.link_conseil} onClick={() => setType(popUpType.FORMCONSEIL)}>Besoin d'être conseillé ?</Link>
                {user?.user ?
                    <div className={styles.username}>Bienvenue{" " + username}</div>
                :
                    <Link to="/login">
                        <button className={styles.buttonLogin}>Connexion</button>
                    </Link>
                }
                {cookies.user ?
                    <button onClick={() => {logout()}} className={styles.buttonSignin}>Déconnexion</button>
                :
                    <Link to="/signin">
                        <button className={styles.buttonSignin}>Inscription</button>
                    </Link>
                }
            </div>
        </div>
    )
}

export default HomeBar
