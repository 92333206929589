import React from 'react'
import { Link } from "react-router-dom";

import styles from './categories.module.css';
import './firstLast.css';

function strToUrlName(str) {
    return str
        .split(' ')
        .map((word) => word.toLowerCase())
        .join('_');
}

function Question( { title, urlTitle, user }) {
    return (
        <div className={styles.questionLinkContainer}>
            <Link className={styles.questionLink} to={"/questions/" + urlTitle}>
                <div className={styles.questionBox}>
                    <div className={styles.questionSpace}>-</div>
                    <div className={styles.questionTitle}>{ title }</div>
                    <div className={styles.questionUser}>{ user }</div>
                </div>
            </Link>
        </div>
    )
}

function Category( { title, questions, customId }) {
    const [expanded, setExpanded] = React.useState(false)

    return (
        <div>
            <div id={customId} className={`${styles.innerCard} ${expanded && customId !== "last" ? styles.borderBot : null}`} onClick={() => setExpanded(!expanded)}>
                <div className={styles.cardTitle}>{title}</div>
                <div className={styles.arrowDown}><img className={expanded ? styles.arrowUpImg : styles.arrowDownImg} src='arrow_down.png' alt="arrow_down"></img></div>
            </div>
            {expanded && (questions.lenght > 0) ?
                <div className={styles.greySizedBox}></div>
                : null}
            <div className={expanded ? styles.animatedQuestions : styles.noAnimation}>
                {questions.map((elem) => {
                    return (
                        expanded ? <Question key={elem.question.id} title={elem.question.Title} urlTitle={elem.question.UrlTitle}></Question> : null
                    )
                })}
            </div>
            {expanded && (questions.lenght > 0) ?
                <div className={styles.greySizedBox}></div>
                : null}
        </div>
    )
}

function Categories( { categories }) {
    return (
        <div className={styles.categorieContainer}>
            { categories?.map((elem, index) => {
                var customId = null
                if (index === 0) {
                    customId = "first"
                } else if (index === categories.length - 1) {
                    customId = "last"
                }
                return (
                    // <Category key={elem.category.id} title={elem.category.Title} questions={elem.questions} customId={customId}></Category>
                    <Category key={elem.category.id} title={elem.category.Title} questions={elem.questions} customId={customId}></Category>
                )
            }) }
        </div>
    )
}

export { Categories, Question , strToUrlName }