import React from 'react'
import Request from '../Usefull/RequestApi'
import { useCookies } from 'react-cookie';
import styles from './Log.module.css';
import { Link } from 'react-router-dom';
import HomeBar from '../HomePage/HomeBar';
import Footer from '../layout/Footer';
import { ScriptKind } from 'typescript';

function Login() {
    const [, setCookie] = useCookies(["user"]);
    const [, setId] = useCookies(['id'])
    const [status, setStatus] = React.useState("")

    async function loginDB(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const target = e.target as HTMLTextAreaElement;
        const email = (target.children.namedItem("email") as HTMLInputElement).value
        const password = (target.children.namedItem("password") as HTMLInputElement).value
        if (email === "" || password === "")
        return
        Request.logIn(email, password).then((res) => {
            if (res.error) {
                setStatus(res.error)
            } else {
                setCookie('user', res["username"])
                setId('id', res["id"])
                window.location.assign(window.location.href.replace('login', ''))
            }
        })
    }

    return (
        <div className={styles.logPage}>
            <HomeBar></HomeBar>
            <div className={styles.logContainer}>
                <div className={styles.title}>Connexion</div>
                <form className={styles.form} onSubmit={(e) => loginDB(e)}>
                    <input className={styles.input} name="email" placeholder="Email"></input>
                    <input className={styles.input} type="password" name="password" placeholder="Mot de passe"></input>
                    <Link className={styles.forgotten} to="/reset-password">Mot de passe oublié ?</Link>
                    <div className={styles.errorStatus}>{status}</div>
                    <button type='submit' className={styles.button}>Connexion</button>
                </form>
                <div className={styles.infoContainer}>
                    <div className={styles.infoAccount}>Vous n'avez pas de compte ?
                        <Link className={styles.infoLink} to='/signin'>Créez en un ici</Link>
                    </div>
                </div>
                <div id="g_id_onload"
                    data-client_id="336665365565-qp3t8h5b6a7ob2rp9trc6hi60msvfu1s.apps.googleusercontent.com"
                    data-context="signin"
                    data-ux_mode="popup"
                    data-login_uri="https://guide-aux-energies.fr/"
                    data-auto_prompt="false"
                    data-callback="handleClick">
                </div>

                <div className="g_id_signin"
                    data-type="standard"
                    data-shape="pill"
                    data-theme="outline"
                    data-text="signin_with"
                    data-size="large"
                    data-locale="fr"
                    style={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "center",
                        }}
                    data-logo_alignment="left">
                </div>
            </div>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <Footer/>
        </div>
    )
}

export default Login
