import React from 'react'
import Request from '../Usefull/RequestApi'
import styles from './Log.module.css';
import { Link } from 'react-router-dom';
import HomeBar from '../HomePage/HomeBar';
import Footer from '../layout/Footer';


function ResetPassword() {
    const [successMsg, setSuccessMsg] = React.useState("")
    const [errorMsg, setErrorMsg] = React.useState("")

    async function reset_password(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        const target = e.target as HTMLTextAreaElement;
        const email = (target.children.namedItem("email") as HTMLInputElement).value
        if (email === "")
            return
        Request.resetPassword(email).then((res) => {
            if (res.error) {
                setErrorMsg(res.error)
                setSuccessMsg('')
            } else {
                setSuccessMsg(res)
                setErrorMsg('')
            }
        })
    }

    return (
        <div className={styles.logPage}>
            <HomeBar></HomeBar>
            <div className={styles.resetContainer}>
                <div className={styles.title}>Réinitialisez votre mot de passe</div>
                <div className={styles.sentence}>Pour réinitialiser votre mot de passe veuillez entrer votre adresse email ci-dessous.</div>
                <form className={styles.form} onSubmit={(e) => reset_password(e)}>
                    <input className={styles.mail_input} type="email" name="email" placeholder="Email"></input>
                    <div className={styles.errorStatusReset}>{errorMsg}</div>
                    <div className={styles.successStatusReset}>{successMsg}</div>
                    <div className={styles.reset_ligne}>
                        <Link to="/login" className={styles.back_login}>Retour connexion</Link>
                        <button type='submit' className={styles.reset_button}>Réinitialiser</button>
                    </div>
                </form>
            </div>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <Footer/>
        </div>
    )
}

export default ResetPassword
