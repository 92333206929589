import React from 'react'
import { useNavigate } from "react-router-dom"
import styles from '../LogPage/Log.module.css';
import HomeBar from '../HomePage/HomeBar';
import Footer from '../layout/Footer';
import { useCookies } from 'react-cookie';


function NotFound() {

    let navigate = useNavigate();
    const routeChange = () => {
        let path = `/`;
        navigate(path);
    }

    return (
        <div className={styles.logPage}>
            <HomeBar></HomeBar>
            <div className={styles.logContainer}>
                <div className={styles.title}>Eror 404</div>
                <div className={styles.title}>Page Inexistante</div>
                <button className={styles.button} onClick={routeChange}>Page d'acceuil</button>
            </div>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <Footer/>
        </div>
    )
}

export default NotFound;