import axios from 'axios'

const prefixUrlApi = "https://backend.guide-aux-energies.fr/api/"
// const prefixUrlApi = "http://127.0.0.1:8000/api/"

const unexpectedError = {
    error: "Une erreur non expectée s'est produite - Contactez le support pour plus d'informations"
}

class RequestApi {
    async getCategories() {
        const response = await axios.get(prefixUrlApi + 'categories/')
        return response.data
    }
    async postQuestion(formdata) {
        const data = formdata
        return axios.post(prefixUrlApi + 'createQuestion/', data).then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
            return unexpectedError
        })
    }
    async postPhoneNumber(userId, phoneNumber) {
        const data = {
            "Phone": phoneNumber,
            "UserId": userId
        }
        return axios.post(prefixUrlApi + 'update_phone/', data).then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
            return unexpectedError
        })
    }
    async postAnswer(description, questionId, userId) {
        const data = {
            "description": description,
            "questionId": questionId,
            "userId": userId
        }
        const response = await axios.post(prefixUrlApi + 'createAnswer/', data)
        return response.data
    }
    async postOfficialAnswer(description, questionId) {
        const data = {
            "description": description,
            "questionId": questionId,
        }
        const response = await axios.post(prefixUrlApi + 'create_official_answer/', data)
        return response.data
    }
    async searchQuestions(title) {
        const response = await axios.get(prefixUrlApi + 'questionsQuery/' + title + '/')
        return response.data
    }
    async getQuestion(urlTitle) {
        return axios.get(prefixUrlApi + 'question/' + urlTitle + '/').then((response) => {
            if (response.data === "Question not found") {
                throw response.data
            }
            return response.data
        }).catch((error) => {
            throw error
        })

    }
    async getAnswers(id) {
        const response = await axios.get(prefixUrlApi + 'answers/' + id)
        return response.data
    }
    async postLittleAnswer(description, answerId, userId) {
        const data = {
            "description": description,
            "answerId": answerId,
            "userId": userId
        }
        const response = await axios.post(prefixUrlApi + 'createLittleAnswer/', data)
        return response.data
    }
    async logIn(email, password) {
        const data = {
            "email": email,
            "password": password
        }
        return axios.post(prefixUrlApi + "login/", data).then((response) => {
            return response.data
        }).catch((error) => {
            return unexpectedError
        })
    }

    async signUp(username, email, password, phone) {
        const data = {
            "username": username,
            "email": email,
            "password": password,
            "phone": phone
        }
        return axios.post(prefixUrlApi + "register/", data).then((response) => {
            return response.data
        }).catch((error) => {
            return error.response.data
        })
    }

    async resetPassword(email) {
        const data = {
            "email": email,
        }
        return axios.post(prefixUrlApi + "resetPassword/", data).then((response) => {
            return response.data
        }).catch((error) => {
            return unexpectedError
        })
    }

    async getModes_chauffage() {
        const response = await axios.get(prefixUrlApi + 'modes_chauffage/')
        return response.data
    }

    async sendForm(formdata) {
        const data = formdata
        return axios.post(prefixUrlApi + 'post_form_infos/', data).then((response) => {
            return response.data
        }).catch((error) => {
            console.log(error)
            return unexpectedError
        })
    }

    async getUserAdmin(userId) {
        if (userId === undefined) {
            return false
        }
        const response = await axios.get(prefixUrlApi + 'user_admin/' + userId + '/')
        return response.data
    }
}

export default new RequestApi()
