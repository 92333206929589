import { Link } from 'react-router-dom'
import styles from './footer.module.css'


function Footer() {

  return (
    <div className={styles.container}>
      <div className={styles.text_center}>
        <Link className={styles.link} to="/legal-notice">Politique de confidentialité</Link>
      </div>
    </div>
  );

};

export default Footer;