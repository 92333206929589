import React from 'react'
import Request from '../Usefull/RequestApi'
import { useCookies } from 'react-cookie';
import styles from './questionPage.module.css';

export default function AnswerResponses( { answerId } ) {
    const [hidden, setHidden] = React.useState(true)
    const [hint, setHint] = React.useState("Plus >>")
    const [answers, setAnswers] = React.useState([])
    const [cookies] = useCookies(["id"]);
    const [errorMsg, setErrorMsg] = React.useState("")

    function more() {
        if (!hidden) {
            setHint("Plus >>")
            setAnswers([])
            setHidden(true)
        } else {
            Request.getAnswers(answerId).then((response) => {
                setHint("Moins <<")
                setAnswers(response)
                setHidden(false)    
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    function postAnswer(e) {
        e.preventDefault()
        const description = e.target.children.description.value
        const userId = cookies.id
        Request.postLittleAnswer(description, answerId, userId).then((response) => {
            Request.getAnswers(answerId).then((response) => {
                setAnswers(response)
            }).catch((error) => {
                console.log(error)
            })
            if (response.error){
                setErrorMsg(response.error)
            }else{
                setErrorMsg('')
            }
        }).catch((error) => {
            console.log(error)
        })
        e.target.children.description.value = ""
    }

    function capitalize(string) {
        if (string === undefined)
            return
        return string[0].toUpperCase() + string.substring(1)
    }
    return (
        <div>
            <div className={styles.moreButton} onClick={() => {more()}}>{hint}</div>
            { hidden ? null :
                <form className={styles.answerForm} onSubmit={(e) => {postAnswer(e)}}>
                    <textarea className={styles.littleAnswerField} name="description" placeholder="Ecrivez votre reponse"></textarea>
                    <div className={styles.errorStatusReset}>{errorMsg}</div>
                    <button className={styles.littleSubmitButton} type='submit'>Envoyer</button>
                </form>
            }
            { hidden ? null :
                answers?.map((elem, index) => {
                    return (
                        <div key={index} className={styles.answersToAnswer} key={elem.id}>
                            <div className={styles.answeredAuthor}>{capitalize(elem.user.username)}</div>
                            <div className={styles.answeredText}>{elem.answer.Description}</div>
                        </div>
                    )
                })
            }
        </div>
    )
}