import React from 'react'
import MetaTags from 'react-meta-tags';
import { useCookies } from 'react-cookie';

import usePopUp from "../Usefull/usePopUp";
import Modal, { popUpType } from "../Usefull/PopUp";
import { Categories } from '../Usefull/Categories';
import SearchBar from '../Usefull/SearchBar';
import Request from '../Usefull/RequestApi';
import HomeBar from './HomeBar';

import styles from './homePage.module.css'
import Footer from '../layout/Footer';

function HomePage() {
    const { type, setType } = usePopUp();
    const [categories, setCategories] = React.useState([]);
    const [cookies] = useCookies(["user"]);

    React.useEffect(() => {
        Request.getCategories().then((categories) => { setCategories(categories) })
    }, [type]);
    return (
        <div>
            <MetaTags>
                <title>Guide aux énergies</title>
                <meta property="og:description" content="Guide aux énergieS"/>
                <meta property="og:title" content="Guide aux énergies"></meta>
            </MetaTags>
            <Modal type={type} setType={setType} categories={categories}/>
            <img className={styles.imgWhy} src="why.png" alt="why"/>
            <img className={styles.imgMessage} src="message.png" alt="message"/>
            <img className={styles.imgQuestion} src="question.png" alt="question"/>
            <img className={styles.imgPhone} src="phone.png" alt="phone"/>
            <HomeBar></HomeBar>
            <div className={styles.homepage}>
                <div className={styles.homeDescription}>
                    Votre plateforme pour conseiller et aider les particuliers <br/> à la réalisation de leur travaux
                </div>
                <button className={styles.button} onClick={() => {cookies.user ? setType(popUpType.CREATION_QUESTION) : setType(popUpType.NOT_CONNECTED)}}>Je pose ma question ?</button>
                <div className={styles.redText}>Soyez informé de chaque réponse apportée</div>
                <div className={styles.or}>OU</div>
                <SearchBar></SearchBar>
                    <div className={styles.sizedBox}></div>
                <div className={styles.homeDescription}>Les questions publiques</div>
                <div className={styles.sizedBox}></div>
                <Categories categories={categories}></Categories>
            </div>
            <Footer/>
        </div>
    )
}

export default HomePage
