import React from 'react'
import { Link, useNavigate } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import { useLocation } from 'react-router-dom'
import { useCookies } from 'react-cookie';

import AnswerResponses from './AnswerResponses';
import UseModal from "../Usefull/usePopUp";
import Request from '../Usefull/RequestApi'
import QuestionBar from './QuestionBar';
import styles from './questionPage.module.css'
import Footer from '../layout/Footer';


function QuestionPage() {
    const navigate = useNavigate();
    let location = useLocation();
    const urlTitle = window.location.href.split("questions/")[1]
    const [cookies, setCookie, removeCookie] = useCookies(["id"]);

    const { isShowing, toggle } = UseModal();

    const [question, setQuestion] = React.useState();
    const time = question?.question?.time
    const [categories, setCategories] = React.useState([]);
    const [found, setFound] = React.useState(true)
    const [isAdmin, setAdmin] = React.useState(true)
    const [errorMsg, setErrorMsg] = React.useState("")

    React.useEffect(() => {
        Request.getCategories().then((categories) => { setCategories(categories) })
    }, [isShowing, setCategories, urlTitle]);

    async function getQuestion() {
        Request.getQuestion(urlTitle).then((newQuestion) => {
            setQuestion(newQuestion)
            setFound(true)
        }).catch((error) => {
            console.log(error)
            setFound(false)
        })
    }

    React.useEffect(() => {
        getQuestion()
    }, [urlTitle]);
    function displayAnswers() {
        return (
            <div className={styles.answersContainer}>
                <div className={styles.answerTitle}>Réponses</div>
                <div className={styles.allAnswered}>
                    { question?.answers.map((elem) => {
                        return (
                            <div key={elem.answer.id} className={styles.answeredContainer}>
                                <img className={styles.answeredImg} src="../verified.png" alt="message"/>
                                <div className={styles.answeredAuthor}>{capitalize(elem.user.username)}</div>
                                <div className={styles.answeredText}>{elem.answer.Description}</div>
                                <AnswerResponses answerId={elem.answer.id}></AnswerResponses>
                            </div>
                        )
                    }) }
                </div>
            </div>
        )
    }
    async function isUserAdmin() {
        Request.getUserAdmin(cookies.id).then((isAdmin) => { setAdmin(isAdmin) })
    }
    React.useEffect(() => {
        isUserAdmin()
    }, [cookies]);
    function displayOfficialAnswers() {
        if (isAdmin && !question?.officialAnswer.length) {
            return (
                <div className={styles.officialAnswerContainer}>
                    <div className={styles.hintContainer}>
                        <img className={styles.hintImg} src="../hint.png" alt="why"/>
                        <div className={styles.hintText}>Réponse d'un expert conseiller</div>
                    </div>
                    {question?.officialAnswer?.map((elem) => {
                        return <div className={styles.officialAnswerDescription} key={elem.id}>{elem.Description}</div>
                    })}
                    <div className={styles.answerFieldContainer}>
                        <img className={styles.answerFieldImg} src="../message.png" alt="message"/>
                        <form id="answerForm" className={styles.answerForm} onSubmit={(e) => {postOfficialAnswer(e)}}>
                            <textarea name="description" className={styles.answerField} placeholder="Répondre officiellement"></textarea>
                            <div className={styles.errorStatusReset}>{errorMsg}</div>
                            <button className={styles.submitButton} type="submit">Envoyer</button>
                        </form>
                    </div>
                </div>
            )
        }
        return (
            <div className={styles.officialAnswerContainer}>
                <div className={styles.hintContainer}>
                    <img className={styles.hintImg} src="../hint.png" alt="why"/>
                    <div className={styles.hintText}>Réponse d'un expert conseiller</div>
                </div>
                {question?.officialAnswer?.map((elem) => {
                    return <div className={styles.officialAnswerDescription} key={elem.id}>{elem.Description}</div>
                })}
            </div>
        )
    }
    function postAnswer(e) {
        e.preventDefault()
        const description = e.target.children.description.value
        Request.postAnswer(description, question.question.id, cookies.id).then((response) => {
            getQuestion()
            if (response.error) {
                setErrorMsg(response.error)
            }else{
                setErrorMsg('')
            }
        }).catch((error) => {
            console.log(error)
        })
        e.target.children.description.value = ""
    }
    function postOfficialAnswer(e) {
        e.preventDefault()
        const description = e.target.children.description.value
        Request.postOfficialAnswer(description, question.question.id).then((response) => {
            getQuestion()
            if (response.error) {
                setErrorMsg(response.error)
            }else{
                setErrorMsg('')
            }
        }).catch((error) => {
            console.log(error)
        })
        e.target.children.description.value = ""
    }
    function capitalize(string) {
        if (string === undefined)
            return
        return string[0].toUpperCase() + string.substring(1)
    }
    function displayHeader() {
        return (
            <div className={styles.headerContainer}>
                <div className={styles.path}>
                    <div className={styles.pathCategoryTitle}>{question?.category.Title}</div>
                    <img className={styles.pathImg} src="../arrow_right.png" alt="arrow_right"/>
                    <div className={styles.pathCategoryTitle}>{question?.question?.Title}</div>
                    <img className={styles.pathImg} src="../arrow_right.png" alt="arrow_right"/>
                    <div className={styles.pathCategoryTitle}>{question?.question?.date}</div>
                    <text>&nbsp; à &nbsp;</text>
                    <div className={styles.pathCategoryTitle}>{time[0] + time[1] + time[2] + time[3] + time[4]}</div>
                </div>
                <div className={styles.headerTitle}>{question?.question?.Title}</div>
                <div className={styles.headerUsername}>{capitalize(question?.user?.username == null ? question?.question?.user_not_registered: question?.user?.username)}</div>
                <div className={styles.headerDescription}>{question?.question?.Description}</div>
                {question?.question?.photo ?
                    <div className={styles.link_photo}><a href={question?.question?.photo}>Photo associée</a></div>
                : <div></div>}
                <div className={styles.answerFieldContainer}>
                    <img className={styles.answerFieldImg} src="../message.png" alt="message"/>
                    <form id="answerForm" className={styles.answerForm} onSubmit={(e) => {postAnswer(e)}}>
                        <textarea name="description" className={styles.answerField} placeholder="Répondre..."></textarea>
                        <div className={styles.errorStatusReset}>{errorMsg}</div>
                        <button className={styles.submitButton} type="submit">Envoyer</button>
                    </form>
                </div>
                { displayOfficialAnswers() }
            </div>
            )
    }
    return (
        <>
            {!found ? navigate('/*') :
            <div>
                <MetaTags>
                    <title>Guide aux énergies | {question?.question.Title}</title>
                    <meta property="og:description" content={question?.question.Title}/>
                    <meta property="og:title" content={question?.question.Description}></meta>
                </MetaTags>
                <button className={styles.buttonRetour} onClick={() => navigate(-1)}>Retour</button>
                <QuestionBar></QuestionBar>
                <div className={styles.questionPage}>
                    <div className={styles.contentContainer}>
                        { displayHeader() }
                        { displayAnswers() }
                        <div className={styles.floatingCategories}>
                                <div className={styles.floatCategoryTitle}>{question?.category.Title}</div>
                                { question?.categoryQuestions.map((elem) => {
                                    return (
                                        <Link key={elem.id} className={styles.questionLink} to={"/questions/" + elem.UrlTitle} state={{id: elem.id}}>
                                            <div className={styles.floatTitleContainer}>
                                                <img className={styles.floatTitleImg} src="../mini_question.png" alt="mini_question"/>
                                                <div className={styles.floatTitle} key={elem.id}>{elem.Title}</div>
                                            </div>
                                        </Link>
                                    )
                                }) }
                        </div>
                    </div>
                </div>
                <Footer/>
            </div>
            }
        </>
    )
}

export default QuestionPage
