import React, { useEffect } from 'react'
import styles from './Log.module.css';
import { useParams } from 'react-router-dom';
import ChangePasswordBar from './ChangePasswordBar';
import Footer from '../layout/Footer';
import axios from 'axios';

const prefixUrlApi = "https://backend.guide-aux-energies.fr/api/"
// const prefixUrlApi = "http://127.0.0.1:8000/api/"

function ForgotChangePassword() {
    const [successMsg, setSuccessMsg] = React.useState("")
    const [errorMsg, setErrorMsg] = React.useState("")

    const [userData, setUserData] = React.useState({
        password : '',
    });

    const {user_id} = useParams();
    const {token} = useParams();

    const handleChange=(event: React.ChangeEvent<HTMLInputElement>)=>{
        setUserData({
            ...userData,
            [event.target.name]:event.target.value
        });
    }

    const submitForm=()=>{
        const userFormData = new FormData();
        userFormData.append('password', userData.password)
        try{
            axios.post(prefixUrlApi + "password-reset/" + user_id + "/" + token + "/", userFormData)
            .then((res) => {
                if(res.data.bool===true){
                    setSuccessMsg(res.data.msg)
                    setErrorMsg('')
                }else{
                    setErrorMsg(res.data.msg)
                    setSuccessMsg('')
                }
            });
        }catch(error){
            console.log(error)
        }
    }

    useEffect(() =>{
        document.title = 'Réinitialiser mot de passe';
    })


    return (
        <div className={styles.logPage}>
            <ChangePasswordBar></ChangePasswordBar>
            <div className={styles.resetContainer}>
                <div className={styles.title}>Entrez votre nouveau mot de passe</div>
                <div className={styles.form} >
                    <input className={styles.mail_input} type="password" name="password" value={userData.password} onChange={handleChange} placeholder="Mot de passe"></input>
                    <div className={styles.successStatusReset}>{successMsg}</div>
                    <div className={styles.errorStatusReset}>{errorMsg}</div>
                    <div className={styles.reset_ligne}>
                        <button type='submit' onClick={submitForm} className={styles.reset_button}>Valider</button>
                    </div>
                </div>
            </div>
            <img className={styles.imgWhy} src="../../why.png" alt="why"/>
            <img className={styles.imgMessage} src="../../message.png" alt="message"/>
            <img className={styles.imgQuestion} src="../../question.png" alt="question"/>
            <img className={styles.imgPhone} src="../../phone.png" alt="phone"/>
            <Footer/>
        </div>
    )
}

export default ForgotChangePassword
