import React from 'react'
import { Link } from 'react-router-dom'
import styles from '../HomePage/homePage.module.css';
import usePopUp from "../Usefull/usePopUpForm";
import Modal, { popUpType } from "../Usefull/PopUpForm";
import Request from '../Usefull/RequestApi';


function ChangePasswordBar() {
    const [categories, setCategories] = React.useState([]);
    const [modes_chauffage, setModes_chauffage] = React.useState([]);
    const { type, setType } = usePopUp();

    React.useEffect(() => {
        Request.getCategories().then((categories) => { setCategories(categories) })
        Request.getModes_chauffage().then((modes_chauffage) => { setModes_chauffage(modes_chauffage) })
    }, [type]);

    return (
        <div className={styles.appBar}>
            <Modal type={type} setType={setType} categories={categories} modes_chauffage={modes_chauffage}/>
            <Link className={styles.homeLink} to={"/"}>
                <img className={styles.imgLogo} src="../../logo.png" alt="logo"></img>
                <h1 className={styles.title}>Guide aux énergies</h1>
            </Link>
            <div className={styles.logButtons}>
                <Link to="" className={styles.link_conseil} onClick={() => setType(popUpType.FORMCONSEIL)}>Besoin d'être conseillé ?</Link>
                <Link to="/login">
                    <button className={styles.buttonLogin}>Connexion</button>
                </Link>
                <Link to="/signin">
                    <button className={styles.buttonSignin}>Inscription</button>
                </Link>
            </div>
        </div>
    )
}

export default ChangePasswordBar
